






































































import {IntegralGoods} from "@/request/marketing/IntegralGoods";

export default {
    data() {
        let self: any =this;
        return {
            modal: IntegralGoods,
            search: [
                {
                    type: 'Input',
                    label: '名称',
                    key: 'name',
                    width: '140px',
                    placeholder: '请填写名称'
                },
                {
                    type: 'DatePicker',
                    pickerType: 'datetimerange',
                    format: 'yyyy-MM-dd HH:mm',
                    label: '日期',
                    width: '220px',
                    placeholder: '请选择日期'
                }
            ],
            formData: {
                name: '',
                cover: '',
                integral: 1,
                num: 1,
                images: [],
                detail: ''
            },
            formRules: {
                name: {required: true, message: '请填写名称', trigger: 'blur'},
                integral: {required: true, type: 'number', message: '请填写所需积分', trigger: 'blur'},
                num: {required: true, type: 'number', message: '请填写库存', trigger: 'blur'}
            },
            columns: [
                {
                    title: '名称',
                    key: 'name',
                    minWidth: 120
                },
                {
                    title: '状态',
                    key: 'status',
                    minWidth: 100,
                    render: (h: any, p: any) => h('p', p.row.status===0?'下线': '上线')
                },
                {
                    title: '封面',
                    key: 'cover',
                    minWidth: 120,
                    render: (h: any, params: any) => {
                        return h('img', {
                            style: {
                                borderRadius: '3px',
                                height: '40px',
                                width: '40px',
                                marginTop: '5px'
                            },
                            attrs: {
                                src: params.row.cover
                            }
                        })
                    }
                },
                {
                    title: '所需积分',
                    key: 'integral',
                    minWidth: 120
                },
                {
                    title: '库存数量',
                    key: 'num',
                    minWidth: 120
                },
                {
                    title: '最近上线时间',
                    key: 'onlineTime',
                    minWidth: 180
                },
                {
                    title: '创建时间',
                    key: 'createTime',
                    minWidth: 180
                }
            ]
        }
    },
    created() {
    },
    methods: {
        createNew() {
            let self: any = this;
            delete self.formData.id;
            self.formData.images = [];
            self.$refs['el-editor'].setHtml('');
        },
        edit(row: any) {
            let self: any = this;
            self.formData = row;
            self.$refs['el-editor'].setHtml(row.detail);
        },
        submit() {
            let self: any = this;
            let params = {...self.formData};
            self.$refs['el-form'].validate((valid: boolean) => {
                if (valid) {
                    self.$refs['el-table'].doSubmitWithValidate(params).then(() => {

                    })
                }
            })
        },
        handleView(path: string) {
            let self: any = this;
            self.$refs['preview'].show(path);
        },
        uploadSuccess(url: string) {
            let self: any = this;
            self.formData.images.push(url);
        },
        uploadCoverSuccess(url: string) {
            let self: any = this;
            self.formData.cover = url;
        }
    }
}
